body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-no-repeat bg-center bg-cover bg-fixed bg-custom-black bg-[url('/public/bg.png')];
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-menu-profile {
  @apply w-full font-bold py-3 px-12 mt-4 rounded;
}
.btn-menu-profile {
  @apply bg-white/10;
}
.btn-menu-profile:hover {
  @apply hover:bg-gray-100/30;
} 
.btn-menu-profile.active {
  @apply bg-gradient-to-r from-custom-blue1/80 via-custom-purple1/80 to-pink-500/80 text-white;
}
.btn-menu-profile.active:hover {
  @apply hover:from-custom-blue1 hover:via-custom-purple1 hover:to-pink-500;
}
.btn-home {
  @apply bg-gradient-to-r from-custom-purple1 to-custom-blue1 font-bold text-white text-2xl rounded;
}
.btn-home:hover {
  @apply hover:from-custom-purple1/80 hover:to-custom-blue1/80;
}
.btn-home:disabled {
  @apply disabled:opacity-50 disabled:cursor-not-allowed;
}
.btn-converse, .btn-connect {
  @apply font-bold text-xl text-white bg-gradient-to-r from-custom-purple1 to-custom-blue1 rounded;
}
.btn-converse:not([disabled]):hover, .btn-connect:not([disabled]):hover {
  @apply hover:from-custom-purple1/80 hover:to-custom-blue1/80;
}
.btn-converse:not([disabled]):active, .btn-connect:not([disabled]):active {
  @apply active:from-custom-purple2 active:to-custom-blue2;
}
.btn-confirm-sell {
  @apply text-white md:mb-0 bg-custom-purple2/90 border border-custom-purple1 px-5 py-2 text-sm shadow-sm font-medium tracking-wider rounded-full;
}
.btn-confirm-sell:disabled {
  @apply disabled:opacity-50 disabled:cursor-not-allowed;
}
.btn-confirm-sell:not([disabled]):hover {
  @apply hover:shadow-lg hover:bg-custom-purple2
}
.btn-cancel-sell {
  @apply bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full;
}
.btn-cancel-sell:disabled {
  @apply disabled:opacity-50 disabled:cursor-not-allowed;
}
.btn-cancel-sell:not([disabled]):hover {
  @apply hover:shadow-lg hover:bg-red-600;
}
div[role="spinner"] > span {
  margin-top: 1.25rem!important;
}
.btn-connect > div[role="spinner"] {
  margin-left: auto;
  margin-right: auto;
}
.btn-connect > div[role="spinner"] > span {
  margin-left: 0.5rem!important;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin: 0px;
  text-align: center;
}